import React, { Suspense, lazy, useEffect } from 'react';
import { createTheme, PaletteMode, Theme, Typography } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, textTransform } from '@mui/system';
import { Route, Router, Routes } from 'react-router';
import { SnackbarProvider, OptionsObject } from 'notistack';
import NotFound from './components/navigation/NotFound';
import RequireAuth from './components/navigation/permissions/RequireAuth';
import { useWindowSize } from 'react-helpers';
import Loader from 'react-helpers/src/components/Loader';
import { DEFAULT_FONT_SIZE, MOBILE_FONT_SIZE, NAVBAR_BOTTOM_COLOR } from './styles/styles';
import * as environment from './environments.json';
import useNavigationStore from './store/useNavigationStore';
import { useSearchParams } from 'react-router-dom';
import Login from './pages/login/Login';
import CreateMagicLink from './pages/login/CreateMagicLink';
import LoginWithMagicLink from './pages/login/LoginWithMagicLink';

const Root = lazy(() => import('./pages/Root'));

const App = () => {
    const { isMobile } = useWindowSize();
    const { railRoutes, isDarkMode } = useNavigationStore();
    const theme = createTheme({
        typography: {
            fontFamily: ['Inter'].join(','),
            fontSize: isMobile ? MOBILE_FONT_SIZE : DEFAULT_FONT_SIZE,
        },
        palette: {
            mode: 'dark',
            background: {
                default: NAVBAR_BOTTOM_COLOR,
                paper: '#343434',
            },
            primary: {
                main: '#ffffff',
                contrastText: '#000000',
            },
            secondary: {
                main: '#e54d2e',
            },
            success: {
                main: '#97dc22',
            },
            error: {
                main: '#e54d2e',
            },
            info: {
                main: '#0090ff',
            },
            warning: {
                dark: '#413900',
                main: '#ffe629',
            },
            text: {
                primary: '#fff',
                disabled: '#666',
            },
            divider: '#666',
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                        textTransform: 'none',
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                    },
                },
            },
            MuiPaper: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                    },
                },
            },
            MuiSkeleton: {
                styleOverrides: {
                    root: {
                        borderRadius: 0,
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <SnackbarProvider
                maxSnack={2}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                classes={{ containerRoot: isMobile && railRoutes.length > 0 ? 'rail-padded-z-alert' : 'z-alert' }}
            >
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="create-magic-link" element={<CreateMagicLink />} />
                        <Route path="login-with-magic-link" element={<LoginWithMagicLink />} />
                        <Route path="login" element={<Login />} />
                        <Route path="/*" element={<Root />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
            </SnackbarProvider>
        </ThemeProvider>
    );
};

export default App;
