import axios, { AxiosError } from 'axios';
import * as environment from '../environments.json';
import * as authService from '../services/auth.service';
import { appUrl, crmAppUrl } from '../environments.json';

const defaultOptions = {
    baseURL: environment.apiBaseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': '*',
    },
};

export const isTokenExpired = (error: AxiosError) => {
    return error.response?.headers['token-expired'] === 'true';
};

export const http = axios.create(defaultOptions);

http.interceptors.request.use((config) => {
    if (!config.headers) config.headers = {};
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

const CONFLICT_STATUS_CODE = 409;
http.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        console.log(error);
        try {
            if (error.response?.status === CONFLICT_STATUS_CODE) {
                alert(
                    'The data you are trying to save has been edited by another user. Please refresh to get the latest data.',
                );
            }
            if (isTokenExpired(error)) {
                axios.defaults.headers.common['Authorization'] = '';
                const { data: tokens } = await axios.post<authService.Tokens>(
                    `${environment.apiBaseUrl}/Auth/Refresh`,
                    {
                        refreshToken: localStorage.getItem('refreshToken') as string,
                    },
                );
                localStorage.setItem('token', tokens.accessToken);
                localStorage.setItem('refreshToken', tokens.refreshToken);
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokens.accessToken;
                return Promise.resolve(http(error.config));
            }
        } catch {
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

export interface IPaginatedQueryObject {
    searchTerm?: string;
    page?: number;
    pageSize?: number;
}

export const formQueryString = (queryObject?: { [key: string]: any }): string => {
    let queryString = '';
    if (!queryObject) return queryString;
    queryString = '?';
    Object.entries(queryObject).forEach(([key, value]) => {
        if (typeof value === 'object') {
            value.forEach((val: string | number) => {
                queryString += `${key}=${val}&`;
            });
        }
        if (typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean') {
            queryString += `${key}=${value}&`;
        }
    });
    queryString = queryString.slice(0, -1); // remove last char &
    return queryString;
};

export interface HttpGetResponse<T> {
    data: T[];
    count: number;
    nextPage?: number;
    prevPage?: number;
}
