import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { createMagicLink } from '../../services/auth.service';
import { Form, Formik } from 'formik';
import { FormikVitlTextField, SubmitButton } from 'react-helpers/src/components/forms';
import { Box, Button, Container, Typography } from '@mui/material';
import VitlIcon from '../../assets/icons/vitl-icon.png';
import { useNavigate } from 'react-router';

const CreateMagicLink = () => {
    const { showAxiosError, showSuccess, showError } = useNotistack();
    const { mutate: createMagicLinkMutation, isLoading: isCreatingMagicLink } = useMutation(createMagicLink, {
        onSuccess: (data) => {
            showSuccess('A magic link has been sent to your email.');
        },
        onError: (err: AxiosError) => {
            showAxiosError(err);
        },
    });
    const navigate = useNavigate();
    return (
        <Container maxWidth="md" disableGutters>
            <Box p={4} mt={'20vh'}>
                <Box my={2}>
                    <Box mb={4} display="flex" alignItems="center">
                        <img src={VitlIcon} width={150} />
                        <Typography className="font-skrappa" fontSize={72}>
                            |
                        </Typography>
                        <Typography className="font-skrappa" fontSize={48} fontWeight={400} sx={{ ml: 2 }}>
                            VITL CRM
                        </Typography>
                    </Box>
                    <Typography fontWeight={700}>
                        Create your magic link using your email. This email must belong to a valid user within our
                        system or you will not receive a magic link. 🪄 Magic Links are valid for 1 day.
                    </Typography>
                </Box>
                <Formik
                    initialValues={{
                        email: '',
                        referrer: 'crm',
                    }}
                    onSubmit={(values) => {
                        createMagicLinkMutation(values);
                    }}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            <FormikVitlTextField
                                sx={{ mt: 1 }}
                                name="email"
                                label="Email"
                                value={values.email}
                                onChange={(e) => {
                                    setFieldValue('email', e.target.value);
                                }}
                            />
                            <SubmitButton isLoading={isCreatingMagicLink}>Create Magic Link</SubmitButton>
                        </Form>
                    )}
                </Formik>
                <Box my={6}>
                    <Button fullWidth variant="contained" color="primary" onClick={() => navigate('/login')}>
                        Return to Login
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default CreateMagicLink;
