import { useMutation } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { loginWithMagicLink, Tokens } from '../../services/auth.service';
import { AxiosError } from 'axios';
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import VitlIcon from '../../assets/icons/vitl-icon.png';
import { useNavigate, useSearchParams } from 'react-router-dom';

const LoginWithMagicLink = () => {
    const { showAxiosError, showSuccess, showError } = useNotistack();
    const navigate = useNavigate();
    const { mutate, isLoading } = useMutation(loginWithMagicLink, {
        onSuccess: (response: Tokens) => {
            showSuccess('Valid magic link! Logging you in...');
            localStorage.setItem('token', response.accessToken);
            localStorage.setItem('refreshToken', response.refreshToken);
            if (window.location.search.includes('referrer')) {
                window.location.href = `${window.location.search.split('=')[1]}?token=${
                    response.accessToken
                }&refreshToken=${response.refreshToken}`;
            } else navigate('/');
        },
        onError: (err: AxiosError) => {
            showAxiosError(err);
        },
    });
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const email = searchParams.get('email');
        const token = searchParams.get('token');
        if (!email || !token) return;
        mutate({
            email,
            token,
        });
    }, []);
    return (
        <Container maxWidth="md">
            <Box display="flex" alignItems="center" flexDirection="column" gap={4} mt={'20vh'}>
                <Box mb={4} display="flex" alignItems="center">
                    <img src={VitlIcon} width={150} />
                    <Typography className="font-skrappa" fontSize={72}>
                        |
                    </Typography>
                    <Typography className="font-skrappa" fontSize={48} fontWeight={400} sx={{ ml: 2 }}>
                        VITL CRM
                    </Typography>
                </Box>
                {isLoading && (
                    <>
                        <Typography variant="h5">🪄 Checking your magic link...</Typography>
                        <CircularProgress size={64} />
                    </>
                )}
            </Box>
        </Container>
    );
};

export default LoginWithMagicLink;
