import { Input, TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import React from 'react';

type FormikVitlTextFieldProps = {
    name: string;
    label: string;
} & TextFieldProps;

const FormikVitlTextField = ({ name, label, ...rest }: FormikVitlTextFieldProps) => {
    const [field, meta] = useField(name);
    return (
        <TextField
            {...field}
            label={label}
            error={!!meta.error && meta.touched}
            fullWidth
            helperText={meta.error && meta.touched ? meta.error : null}
            {...rest}
        />
    );
};

export default FormikVitlTextField;
